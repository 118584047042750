import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";
import TextArea from "../FormComponents/TextArea";
import YesNoInput from "../FormComponents/YesNoInput";

const SafeguardingForm = ({ state, dispatch }) => {
  const [comment, setComment] = useState(state.comment || "");
  const [requireSignPosting, setRequireSignPosting] = useState(
    state.requireSignPosting
  );
  const [signPost, setSignPost] = useState(state.signPost || "");
  const [violence, setViolence] = useState(state.violence);
  const [abuse, setAbuse] = useState(state.abuse || "");
  const [policeInvolvement, setPoliceInvolvement] = useState(
    state.policeInvolvement
  );
  const [policeInvolve, setPoliceInvolve] = useState(state.policeInvolve || "");
  const [socialService, setSocialService] = useState(state.socialService);
  const [socialDetails, setSocialDetails] = useState(state.socialDetails || "");
  const [alcohol, setAlcohol] = useState(state.alcohol);
  const [safeguard, setSafeguard] = useState(state.safeguard || "");
  const [orders, setOrders] = useState(state.orders);
  const [ordersDetails, setOrdersDetails] = useState(state.ordersDetails || "");

  useScrollTo(0, 0, "smooth");

  const onCommentChange = ({ target }) => {
    setComment(target.value);
    dispatch({ type: "COMMENT", payload: target.value });
  };

  const onRequireSignPostingChange = ({ target }) => {
    setRequireSignPosting(target.value);
    dispatch({ type: "REQUIRE_SIGNPOSTING", payload: target.value });
  };

  const onSignPostChange = ({ target }) => {
    setSignPost(target.value);
    dispatch({ type: "SIGNPOST", payload: target.value });
  };

  const onViolenceChange = ({ target }) => {
    setViolence(target.value);
    dispatch({ type: "VIOLENCE", payload: target.value });
  };

  const onAbuseChange = ({ target }) => {
    setAbuse(target.value);
    dispatch({ type: "ABUSE", payload: target.value });
  };

  const onPoliceInvolvementChange = ({ target }) => {
    setPoliceInvolvement(target.value);
    dispatch({ type: "POLICE_INVOLVEMENT", payload: target.value });
  };

  const onPoliceInvolveChange = ({ target }) => {
    setPoliceInvolve(target.value);
    dispatch({ type: "POLICE_INVOLVE", payload: target.value });
  };

  const onSocialServicesChange = ({ target }) => {
    setSocialService(target.value);
    dispatch({ type: "SOCIAL_SERVICES", payload: target.value });
  };

  const onSocialDetailsChange = ({ target }) => {
    setSocialDetails(target.value);
    dispatch({ type: "SOCIAL_DETAILS", payload: target.value });
  };

  const onAlcoholChange = ({ target }) => {
    setAlcohol(target.value);
    dispatch({ type: "ALCOHOL", payload: target.value });
  };

  const onSafeguardChange = ({ target }) => {
    setSafeguard(target.value);
    dispatch({ type: "SAFEGUARD", payload: target.value });
  };

  const onOrdersChange = ({ target }) => {
    setOrders(target.value);
    dispatch({ type: "ORDERS", payload: target.value });
  };

  const onOrdersDetailsChange = ({ target }) => {
    setOrdersDetails(target.value);
    dispatch({ type: "ORDERS_DETAILS", payload: target.value });
  };

  return (
    <div>
      <div className="form-header">
        <h3>Mediation comments & safeguarding</h3>
      </div>
      <div className="d-flex col-flex">
        <div className="form-control">
          <TextArea
            label="Comments from the mediator."
            id="comment"
            onTextChange={onCommentChange}
            value={comment}
            required={true}
            classes="comment"
            rows="20"
            guide="Use this space to provide details of the case. There should be sufficient information so that another mediator can take over the case."
          />
        </div>
      </div>
      <div className="form-control">
        <YesNoInput
          label="Did the client require signposting?"
          name="require-signposting"
          noLabel="no-require-signposting"
          yesLabel="yes-require-signposting"
          checkedTerm={requireSignPosting}
          onOptionChange={onRequireSignPostingChange}
          required={true}
        />
      </div>
      {requireSignPosting === "Yes" && (
        <div className="d-flex" id="col-flex">
          <TextArea
            label="To which organizations did you signpost the client?"
            required={true}
            id="signpost"
            rows="5"
            value={signPost}
            onTextChange={onSignPostChange}
            classes="comment"
          />
        </div>
      )}
      <div className="form-control">
        <YesNoInput
          label="Domestic abuse or violence?"
          name="violence"
          noLabel="no-violence"
          yesLabel="yes-violence"
          checkedTerm={violence}
          onOptionChange={onViolenceChange}
          required={true}
        />
      </div>
      {violence === "Yes" && (
        <div className="d-flex" id="col-flex">
          <TextArea
            label="Please provide details for abuse or violence."
            required={true}
            id="abuse"
            value={abuse}
            onTextChange={onAbuseChange}
            classes="comment"
            rows="5"
            guide="Please give me as much information as possible."
          />
        </div>
      )}
      <div className="form-control">
        <YesNoInput
          label="Any police involvement?"
          name="police-involvement"
          noLabel="no-police-involvement"
          yesLabel="yes-police-involvement"
          checkedTerm={policeInvolvement}
          onOptionChange={onPoliceInvolvementChange}
          required={true}
        />
      </div>
      {policeInvolvement === "Yes" && (
        <div className="d-flex" id="col-flex">
          <TextArea
            label="Please provide details of any incidents involving the police."
            id="police-involve"
            value={policeInvolve}
            onTextChange={onPoliceInvolveChange}
            classes="comment"
            rows="5"
            guide="Please give me as much information as possible."
            required={true}
          />
        </div>
      )}
      <div className="form-control">
        <YesNoInput
          label="Any Social Services involvement?"
          name="ss"
          noLabel="no-ss"
          yesLabel="yes-ss"
          checkedTerm={socialService}
          onOptionChange={onSocialServicesChange}
          required={true}
        />
      </div>
      {socialService === "Yes" && (
        <div className="d-flex" id="col-flex">
          <TextArea
            label="Please provide details of the Social Services Department involvement."
            required={true}
            id="SS-details"
            rows="5"
            value={socialDetails}
            onTextChange={onSocialDetailsChange}
            classes="comment"
            guide="Please give me as much information as possible."
          />
        </div>
      )}
      <div className="form-control">
        <YesNoInput
          label="Are there any safeguarding issues such as drugs or alcohol?"
          name="alcohol"
          noLabel="no-alcohol"
          yesLabel="yes-alcohol"
          checkedTerm={alcohol}
          onOptionChange={onAlcoholChange}
          required={true}
        />
      </div>
      {alcohol === "Yes" && (
        <div className="d-flex" id="col-flex">
          <TextArea
            label="Please provide details of the safeguarding issues."
            required={true}
            id="safeguarding-issues"
            value={safeguard}
            rows="5"
            onTextChange={onSafeguardChange}
            classes="comment"
            guide="Please give me as much information as possible."
          />
        </div>
      )}
      <div className="form-control">
        <YesNoInput
          label="Are there any court orders in place?"
          name="court-orders"
          noLabel="no-court-orders"
          yesLabel="yes-court-orders"
          checkedTerm={orders}
          onOptionChange={onOrdersChange}
          required={true}
        />
      </div>
      {orders === "Yes" && (
        <div className="d-flex" id="col-flex">
          <TextArea
            label="Please provide detailed information about any court orders."
            required={true}
            id="court-orders-details"
            value={ordersDetails}
            rows="5"
            onTextChange={onOrdersDetailsChange}
            classes="comment"
            guide="If any court order is in place, you will need to ask the client to send you a copy of the order before proceeding to a joint mediation session."
          />
        </div>
      )}
    </div>
  );
};

export default SafeguardingForm;
