import { useState } from "react";

import useScrollTo from "../../hooks/useScrollTo";
import SelectInput from "../FormComponents/SelectInput";
import TextInput from "../FormComponents/TextInput";
import YesNoInput from "../FormComponents/YesNoInput";

const aidOptions = [
  "Private",
  "Already entitled for Legal Aid",
  "Private but covered by other party's legal aid",
  "Not yet assessed",
];

const CaseDetailsForm = ({ state, dispatch }) => {
  const [aid, setAid] = useState(state.aid);
  const [appointment, setAppointment] = useState(state.appointment);
  const [payment, setPayment] = useState(state.payment || "");
  const [advPayment, setAdvPayment] = useState(state.advPayment);

  useScrollTo(0, 0, "smooth");

  const onAidChange = ({ target }) => {
    setAid(target.value);
    dispatch({ type: "REMOVE_AID" });
    dispatch({ type: "AID", payload: target.value });
  };

  const onAppointmentChange = ({ target }) => {
    setAppointment(target.value);
    dispatch({ type: "REMOVE_APPOINTMENT" });
    dispatch({ type: "APPOINTMENT", payload: target.value });
  };

  const onPaymentChange = ({ target }) => {
    setPayment(target.value);
    dispatch({ type: "PAYMENT", payload: target.value });
  };

  const onAdvPaymentChange = ({ target }) => {
    setAdvPayment(target.value);
    dispatch({ type: "ADV_PAYMENT", payload: target.value });
  };

  return (
    <div>
      <div className="form-header">
        <h3>Details of the case</h3>
      </div>
      <div className="d-flex" id="col-flex">
        <div className="form-control">
          <label>
            Is the client private or legal aid?
            <span className="color-red">*</span>
          </label>
          <p className="guide-label">
            Please choose from the list by clicking on the box below.
            <strong>
              If C2 is presumably private or not yet assessed, please select
              "Private but covered under C1's LA"
            </strong>
          </p>
          <SelectInput
            options={aidOptions}
            optionValue={aid}
            onOptionChange={onAidChange}
            required={true}
          />
        </div>
      </div>
      {aid === "Private" && (
        <div className="form-control">
          <YesNoInput
            label="Has payment been made for this appointment?"
            name="appointment"
            yesLabel="yesAppointment"
            noLabel="noAppointment"
            checkedTerm={appointment}
            onOptionChange={onAppointmentChange}
            required={true}
          />
        </div>
      )}
      {aid === "Private" && appointment === "No" && (
        <div className="d-flex" id="col-flex">
          <div className="form-control">
            <TextInput
              label="Reasons why payment has not been made?"
              id="payment"
              value={payment}
              onChangeText={onPaymentChange}
              required={true}
            />
          </div>
        </div>
      )}
      {aid === "Private" && (
        <div className="form-control">
          <YesNoInput
            label="Payment in advance explained?"
            name="advPayment"
            yesLabel="yesAdvPayment"
            noLabel="noAdvPayment"
            checkedTerm={advPayment}
            onOptionChange={onAdvPaymentChange}
            required={true}
          />
        </div>
      )}
    </div>
  );
};

export default CaseDetailsForm;
