import Multiselect from "multiselect-react-dropdown";
import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";
import SelectInput from "../FormComponents/SelectInput";
import TextInput from "../FormComponents/TextInput";
import YesNoInput from "../FormComponents/YesNoInput";

const refusalOptions = [
  "Matters agreed",
  "Refusal",
  "Domestic abuse",
  "Safeguarding",
];
const formOptions = ["Face-to-face", "Shuttle", "No preference"];
const legalOptions = ["Child arrangements", "Property & Finance", "All Issues"];
const childConductOptions = ["Yes", "No", "Considering"];
const clientPreferOptions = ["Online", "In Person"];
const clientMakeApplicationOptions = [
  "C100 - Child arrangements application",
  "Form A - Financial order",
  "C100 & Form A - Child arrangements application & Financial order",
  "No application",
];

const TypeOfMediationForm = ({ state, dispatch }) => {
  const [client, setClient] = useState(state.client);
  const [refusal, setRefusal] = useState(state.refusal);
  const [attend, setAttend] = useState(state.attend || "");
  const [legal, setLegal] = useState(state.legal || "");
  const [appropriate, setAppropriate] = useState(state.appropriate);
  const [childInclusive, setChildInclusive] = useState(
    state.childInclusive || ""
  );
  const [adr, setADR] = useState(state.adr);
  const [explainADR, setExplainADR] = useState(state.explainADR || "");
  const [clientPrefer, setClientPrefer] = useState(state.clientPrefer || "");
  const [waiting, setWaiting] = useState(state.waiting);
  const [arrival, setArrival] = useState(state.arrival);
  const [clientMakeApplication, setClientMakeApplication] = useState(
    state.clientMakeApplication || "select"
  );

  useScrollTo(0, 0, "smooth");

  const onClientChange = ({ target }) => {
    setClient(target.value);
    dispatch({ type: "CLIENT", payload: target.value });
  };

  const onSelect = (value) => {
    setRefusal(value);
    dispatch({ type: "REFUSAL", payload: value });
  };

  const onRemove = (value) => {
    setRefusal(value);
    dispatch({ type: "REFUSAL", payload: value });
  };

  const onAttendChange = ({ target }) => {
    setAttend(target.value);
    dispatch({ type: "ATTEND", payload: target.value });
  };

  const onLegalChange = ({ target }) => {
    setLegal(target.value);
    dispatch({ type: "LEGAL", payload: target.value });
  };

  const onAppropriateChange = ({ target }) => {
    setAppropriate(target.value);
    dispatch({ type: "APPROPRIATE", payload: target.value });
  };

  const onChildInclusiveChange = ({ target }) => {
    setChildInclusive(target.value);
    dispatch({ type: "Child_INCLUSIVE", payload: target.value });
  };

  const onADRChange = ({ target }) => {
    setADR(target.value);
    dispatch({ type: "ADR", payload: target.value });
  };

  const onExplainADRChange = ({ target }) => {
    setExplainADR(target.value);
    dispatch({ type: "EXPLAIN_ADR", payload: target.value });
  };

  const onClientPreferChange = ({ target }) => {
    setClientPrefer(target.value);
    dispatch({ type: "CLIENT_PREFER", payload: target.value });
  };

  const onWaitingChange = ({ target }) => {
    setWaiting(target.value);
    dispatch({ type: "WAITING", payload: target.value });
  };

  const onArrivalChange = ({ target }) => {
    setArrival(target.value);
    dispatch({ type: "ARRIVAL", payload: target.value });
  };

  const onClientMakeApplication = ({ target }) => {
    setClientMakeApplication(target.value);
    dispatch({ type: "CLIENT_MAKE_APPLICATION", payload: target.value });
  };

  return (
    <div>
      <div className="form-header">
        <h3>Type of Mediation</h3>
      </div>
      <div className="form-control">
        <YesNoInput
          label="Is the client willing to go ahead with mediation?"
          noLabel="noClient"
          yesLabel="yesClient"
          name="client"
          checkedTerm={client}
          onOptionChange={onClientChange}
          required={true}
        />
      </div>
      {client === "No" && (
        <div className="form-control">
          <label>
            Reasons for refusal<span className="color-red">*</span>
          </label>
          <p className="guide-label">
            Please choose from the list as many options as necessary.
          </p>
          <Multiselect
            options={refusalOptions}
            isObject={false}
            onSelect={onSelect}
            onRemove={onRemove}
            selectedValues={refusal}
          />
        </div>
      )}
      {client === "Yes" && (
        <div className="d-flex form-control" id="col-flex">
          <SelectInput
            label="If the client attends a mediation session, which form of mediation would they prefer?"
            options={formOptions}
            guide="Please choose from the list by clicking on the box below."
            required={true}
            optionValue={attend}
            onOptionChange={onAttendChange}
          />
        </div>
      )}
      <div className="d-flex form-control" id="col-flex">
        <SelectInput
          label="Confirm legal dispute"
          options={legalOptions}
          optionValue={legal}
          onOptionChange={onLegalChange}
          required={true}
          guide="Please choose from the list by clicking on the box below."
        />
      </div>
      <div className="d-flex form-control" id="col-flex">
        <SelectInput
          label="If the client is willing to make an application for court, which application would they make?"
          options={clientMakeApplicationOptions}
          optionValue={clientMakeApplication}
          onOptionChange={onClientMakeApplication}
          required={true}
          boldGuide="It is advised this question is asked to the client, please do not assume the response."
          anotherGuide="Please remind the client the court form is valid for FOUR months only from the date of the MIAM."
          guide="Please choose from the list by clicking on the box below"
        />
      </div>
      {(legal === "Child arrangements" || legal === "All Issues") && (
        <div>
          <YesNoInput
            label="Is Child Inclusive Mediation appropriate to this case?"
            name="appropriate"
            yesLabel="yesAppropriate"
            noLabel="noAppropriate"
            checkedTerm={appropriate}
            onOptionChange={onAppropriateChange}
            required={true}
          />
        </div>
      )}
      {appropriate === "Yes" && legal !== "Property & Finance" && (
        <div className="d-flex form-control" id="col-flex">
          <SelectInput
            label="Does the client wish for Child Inclusive Mediation to be conducted?"
            options={childConductOptions}
            required={true}
            onOptionChange={onChildInclusiveChange}
            optionValue={childInclusive}
          />
        </div>
      )}
      <div className="form-control">
        <YesNoInput
          label="Has information been given to the client about other forms of ADR (Alternative Dispute Resolution), such as solicitor led negotiations, arbitration, court?"
          name="ADR"
          yesLabel="yesADR"
          noLabel="noADR"
          checkedTerm={adr}
          onOptionChange={onADRChange}
          required={true}
        />
      </div>
      {adr === "No" && (
        <div className="d-flex form-control" id="col-flex">
          <TextInput
            label="Please explain why ADR info was not provided."
            id="explainADR"
            required={true}
            value={explainADR}
            onChangeText={onExplainADRChange}
          />
        </div>
      )}
      {client === "Yes" && (
        <div className="d-flex form-control" id="col-flex">
          <SelectInput
            label="Would the client prefer the mediation online or in person?"
            options={clientPreferOptions}
            optionValue={clientPrefer}
            onOptionChange={onClientPreferChange}
            guide="(Explain that online appointments have shorter waiting times)"
            required={true}
          />
        </div>
      )}
      {clientPrefer === "In Person" && client === "Yes" && (
        <div>
          <div className="form-control">
            <YesNoInput
              label="Are separate waiting areas required?"
              name="Waiting"
              yesLabel="yesWaiting"
              noLabel="noWaiting"
              checkedTerm={waiting}
              onOptionChange={onWaitingChange}
              required={true}
            />
          </div>
        </div>
      )}
      {clientPrefer === "In Person" && client === "Yes" && (
        <div>
          <div className="form-control">
            <YesNoInput
              label="Are separate arrival and departure times required?"
              name="Arrival"
              yesLabel="yesArrival"
              noLabel="noArrival"
              checkedTerm={arrival}
              onOptionChange={onArrivalChange}
              required={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TypeOfMediationForm;
