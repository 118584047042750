export const defaultState = {
  mediationLocation: "select",
  mediationClient: "select",
  aid: "select",
  clientPrefer: "select",
  childInclusive: "select",
  legal: "select",
  attend: "select",
  upload: "select",
};

export const reducer = (state, { type, payload }) => {
  // console.log({ state, type, payload });
  switch (type) {
    case "MEDIATION_NAME":
      return { ...state, mediationName: payload };
    case "MEDIATION_EMAIL":
      return { ...state, mediationEmail: payload };
    case "MEDIATION_DATE":
      return { ...state, mediationDate: payload };
    case "MEDIATION_LOCATION":
      return { ...state, mediationLocation: payload };
    case "MEDIATION_SPECIFY_LOCATION":
      return { ...state, mediationSpecifyLocation: payload };
    case "MEDIATION_CLIENT":
      return { ...state, mediationClient: payload };
    case "CLIENT_FIRST_NAME":
      return { ...state, clientFirstName: payload };
    case "CLIENT_SURNAME":
      return { ...state, clientSurname: payload };
    case "CLIENT_EMAIL_CONFIRM":
      return { ...state, clientEmailConfirm: payload };
    case "OTHER_PARTY_SURNAME":
      return { ...state, otherPartySurname: payload };
    case "AID":
      return { ...state, aid: payload };
    case "APPOINTMENT":
      return { ...state, appointment: payload };
    case "PAYMENT":
      return { ...state, payment: payload };
    case "ADV_PAYMENT":
      return { ...state, advPayment: payload };
    case "COMMENT":
      return { ...state, comment: payload };
    case "REQUIRE_SIGNPOSTING":
      return { ...state, requireSignPosting: payload };
    case "SIGNPOST":
      return { ...state, signPost: payload };
    case "VIOLENCE":
      return { ...state, violence: payload };
    case "ABUSE":
      return { ...state, abuse: payload };
    case "POLICE_INVOLVEMENT":
      return { ...state, policeInvolvement: payload };
    case "POLICE_INVOLVE":
      return { ...state, policeInvolve: payload };
    case "SOCIAL_SERVICES":
      return { ...state, socialService: payload };
    case "SOCIAL_DETAILS":
      return { ...state, socialDetails: payload };
    case "ALCOHOL":
      return { ...state, alcohol: payload };
    case "SAFEGUARD":
      return { ...state, safeguard: payload };
    case "ORDERS":
      return { ...state, orders: payload };
    case "ORDERS_DETAILS":
      return { ...state, ordersDetails: payload };
    case "CLIENT":
      return { ...state, client: payload };
    case "REFUSAL":
      return { ...state, refusal: payload };
    case "ATTEND":
      return { ...state, attend: payload };
    case "LEGAL":
      return { ...state, legal: payload };
    case "APPROPRIATE":
      return { ...state, appropriate: payload };
    case "Child_INCLUSIVE":
      return { ...state, childInclusive: payload };
    case "ADR":
      return { ...state, adr: payload };
    case "EXPLAIN_ADR":
      return { ...state, explainADR: payload };
    case "CLIENT_PREFER":
      return { ...state, clientPrefer: payload };
    case "WAITING":
      return { ...state, waiting: payload };
    case "ARRIVAL":
      return { ...state, arrival: payload };
    case "SUITABLE_CASE":
      return { ...state, suitableCase: payload };
    case "COMMENT_CASE":
      return { ...state, commentCase: payload };
    case "UPLOAD":
      return { ...state, upload: payload };
    case "CLIENT_TWO_INVITATION":
      return { ...state, clientTwoInvitation: payload };
    case "DMS_ADMIN":
      return { ...state, DMSAdmin: payload };
    case "C100_FILE":
      return { ...state, c100: payload };
    case "FORMA":
      return { ...state, formA: payload };
    case "AUTO":
      return { ...state, automatically: payload };
    case "CLIENT_TWO":
      return { ...state, clientTwoSendInvitation: payload };
    case "CLIENT_MAKE_APPLICATION":
      return { ...state, clientMakeApplication: payload };
    default:
      return state;
  }
};
