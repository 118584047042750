import { useLocation } from "react-router-dom";
import { onBackHandler, onSubmitHandler } from "../Logic/navFunction";

const FormComponent = ({
  logo,
  currentStepIndex,
  steps,
  step,
  isFirstStep,
  isLastStep,
  next,
  back,
  formPartTwoState,
  id,
  setIsSubmitted,
}) => {
  const { pathname } = useLocation();
  return (
    <div className="full-screen">
      <div className="form-screen">
        <div className="yellow-bar"></div>
        <header>
          <img src={logo} alt="Logo" />
        </header>
        <form
          className="form"
          onSubmit={(e) =>
            onSubmitHandler(
              e,
              next,
              currentStepIndex,
              steps,
              id,
              formPartTwoState,
              pathname,
              setIsSubmitted
            )
          }
        >
          <div className="steps">
            {currentStepIndex + 1} / {steps.length}
          </div>
          {step}
          <div>
            <div className="submit-btn submit">
              {!isFirstStep && (
                <button
                  type="button"
                  className="back-btn"
                  onClick={(e) => onBackHandler(e, back, id, formPartTwoState)}
                >
                  Back
                </button>
              )}
              <button
                type="submit"
                disabled={
                  (currentStepIndex === 0 &&
                    formPartTwoState.mediationLocation === "select") ||
                  (currentStepIndex === 0 &&
                    formPartTwoState.mediationClient === "select") ||
                  (currentStepIndex === 1 &&
                    formPartTwoState.aid === "select") ||
                  (currentStepIndex === 3 &&
                    formPartTwoState.client === "yes" &&
                    formPartTwoState.attend === "select") ||
                  (currentStepIndex === 3 &&
                    formPartTwoState.legal === "select") ||
                  (currentStepIndex === 3 &&
                    (formPartTwoState.legal === "Child arrangements" ||
                      formPartTwoState.legal === "All Issues") &&
                    formPartTwoState.appropriate === "yes" &&
                    formPartTwoState.childInclusive === "select") ||
                  (currentStepIndex === 3 &&
                    formPartTwoState.client === "yes" &&
                    formPartTwoState.clientPrefer === "select") ||
                  (currentStepIndex === 4 &&
                    formPartTwoState.upload === "select") ||
                  (currentStepIndex === 3 &&
                    formPartTwoState.client === "no" &&
                    formPartTwoState.refusal.length === 0)
                }
                className="next-btn"
              >
                {isLastStep ? "Finish" : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormComponent;
