import { client } from "../axios/axios";
import { patchMiamPart2 } from "./patchMiamPart2";

const uploadFileToDrive = (id, formPartTwoState) => {
  const formData = new FormData();
  formData.append("files", formPartTwoState.c100);
  formData.append("files", formPartTwoState.formA);
  client
    .post(`/uploadFiles/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((err) => {
      console.log({ err });
    });
};

export const onSubmitHandler = (
  e,
  next,
  currentStepIndex,
  steps,
  id,
  formPartTwoState,
  pathname,
  setIsSubmitted
) => {
  e.preventDefault();
  if (currentStepIndex + 1 === steps.length) {
    patchMiamPart2(id, formPartTwoState, pathname, setIsSubmitted);
    uploadFileToDrive(id, formPartTwoState);
  }
  next();
};

export const onBackHandler = (e, back) => {
  e.preventDefault();
  back();
};
