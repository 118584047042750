import { useEffect, useReducer, useRef, useState } from "react";

import { useMultiStepForm } from "../hooks/useMultiStepForm";

import logo from "../assets/dms-logotype2.png";

import { defaultState, reducer } from "../Logic/reducer";

import FirstForm from "./FormPages/FirstForm";
import CaseDetailsForm from "./FormPages/CaseDetailsForm";
import SafeguardingForm from "./FormPages/SafeguardingForm";
import TypeOfMediationForm from "./FormPages/TypeOfMediationForm";
import FinalComments from "./FormPages/FinalComments";
import { useParams } from "react-router-dom";
import FormComponent from "./FormComponent";
import Submitted from "./FormPages/Submitted";

const Form = () => {
  const isMounted = useRef(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { id } = useParams();

  console.log({ id });

  let [formPartTwoState, dispatch] = useReducer(
    reducer,
    JSON.parse(localStorage.getItem(`formPartTwoState-${id}`)) || defaultState
  );

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    localStorage.setItem(
      `formPartTwoState-${id}`,
      JSON.stringify(formPartTwoState)
    );
  }, [formPartTwoState, id]);

  const { currentStepIndex, steps, step, isFirstStep, isLastStep, back, next } =
    useMultiStepForm([
      <FirstForm state={formPartTwoState} dispatch={dispatch} />,
      <CaseDetailsForm state={formPartTwoState} dispatch={dispatch} />,
      <SafeguardingForm state={formPartTwoState} dispatch={dispatch} />,
      <TypeOfMediationForm state={formPartTwoState} dispatch={dispatch} />,
      <FinalComments state={formPartTwoState} dispatch={dispatch} />,
    ]);

  return (
    <>
      {isSubmitted === true ? (
        <Submitted />
      ) : (
        <FormComponent
          currentStepIndex={currentStepIndex}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          logo={logo}
          step={step}
          steps={steps}
          formPartTwoState={formPartTwoState}
          back={back}
          next={next}
          id={id}
          setIsSubmitted={setIsSubmitted}
        />
      )}
    </>
  );
};

export default Form;
