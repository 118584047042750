import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";
import SelectInput from "../FormComponents/SelectInput";
import TextArea from "../FormComponents/TextArea";
import YesNoInput from "../FormComponents/YesNoInput";
import { useEffect } from "react";

const uploadOptions = ["No court form required", "C100", "Form A", "Both"];
const clientTwoInvitationOptions = [
  "Yes (Email and SMS & Letter)",
  "No",
  "Email ONLY",
  "SMS ONLY",
  "Letter ONLY",
];
const clientTwoSendInvitationOptions = [
  "No invitation to be sent",
  "Send SMS & email",
  "Send letter only",
  "Send email, SMS and letter",
];

const FinalComments = ({ state, dispatch }) => {
  const [suitableCase, setSuitableCase] = useState(state.suitableCase);
  const [commentCase, setCommentCase] = useState(state.commentCase || "");
  const [upload, setUpload] = useState(state.upload || "");
  const [DMSAdmin, setDMSAdmin] = useState(state.DMSAdmin || "");
  const [clientTwoInvitation, setClientTwoInvitation] = useState(
    state.clientTwoInvitation
  );
  const [c100, setC100] = useState(null);
  const [formA, setFormA] = useState(null);
  const [automatically, setAutomatically] = useState(state.automatically);
  const [clientTwoSendInvitation, setClientTwoSendInvitation] = useState(
    state.clientTwoSendInvitation || "select"
  );

  useScrollTo(0, 0, "smooth");

  useEffect(() => {}, []);

  const onSuitableCaseChange = ({ target }) => {
    setSuitableCase(target.value);
    dispatch({ type: "SUITABLE_CASE", payload: target.value });
    dispatch({ type: "REMOVE_COMMENT_CASE" });
  };

  const onCommentChange = ({ target }) => {
    setCommentCase(target.value);
    dispatch({ type: "COMMENT_CASE", payload: target.value });
  };

  const onUploadChange = ({ target }) => {
    setUpload(target.value);
    dispatch({ type: "UPLOAD", payload: target.value });
  };

  const onAdminChange = ({ target }) => {
    setDMSAdmin(target.value);
    dispatch({ type: "DMS_ADMIN", payload: target.value });
  };

  const onClientTwoInvitation = ({ target }) => {
    setClientTwoInvitation(target.value);
    dispatch({ type: "CLIENT_TWO_INVITATION", payload: target.value });
  };

  const handleFileSelect = (event) => {
    setC100(event.target.files[0]);
  };
  const handleFormAFileSelect = (event) => {
    setFormA(event.target.files[0]);
  };

  const onAutomaticallyChange = ({ target }) => {
    setAutomatically(target.value);
    dispatch({ type: "AUTO", payload: target.value });
  };

  const onClientTwoSendInvitationChange = ({ target }) => {
    setClientTwoSendInvitation(target.value);
    dispatch({ type: "CLIENT_TWO", payload: target.value });
  };

  useEffect(() => {
    dispatch({ type: "C100_FILE", payload: c100 });
    dispatch({ type: "FORMA", payload: formA });
  }, [dispatch, c100, formA]);

  return (
    <div>
      <div className="form-header">
        <h3>Final comments from the mediator</h3>
        <p>
          Thank you for completing this stage of the Mediation Information &
          Assessment Meeting (MIAM).
        </p>
      </div>
      <div className="last-form">
        <div>
          <div className="form-control">
            <YesNoInput
              label="Is the case suitable for mediation?"
              noLabel="noCase"
              yesLabel="yesCase"
              checkedTerm={suitableCase}
              onOptionChange={onSuitableCaseChange}
              required={true}
            />
          </div>
        </div>
        {suitableCase === "No" && (
          <div className="d-flex col-flex">
            <div className="form-control">
              <TextArea
                label="Please add some comment about why the case is not suitable for mediation."
                id="commentCase"
                required={true}
                value={commentCase}
                onTextChange={onCommentChange}
                classes="comment height-102"
              />
            </div>
          </div>
        )}
        <div className="d-flex" id="col-flex">
          <SelectInput
            label="Do you want/need to upload a court form?"
            options={uploadOptions}
            onOptionChange={onUploadChange}
            required={true}
            optionValue={upload}
          />
        </div>
        {(upload === "C100" || upload === "Form A" || upload === "Both") && (
          <>
            <div className="form-control">
              <YesNoInput
                label="Do you want the court form(s) to be sent to the client automatically after the submission of the MIAM Part 2?"
                noLabel="no-auto"
                yesLabel="yes-auto"
                name="auto"
                required={true}
                checkedTerm={automatically}
                onOptionChange={onAutomaticallyChange}
              />
            </div>
            {automatically === "Yes" && (
              <div className="d-flex" id="col-flex">
                <SelectInput
                  label="Do you want to send an invitation to Client 2?"
                  options={clientTwoSendInvitationOptions}
                  onOptionChange={onClientTwoSendInvitationChange}
                  required={true}
                  optionValue={clientTwoSendInvitation}
                  guide="Please choose from the list by clicking on the box below."
                />
              </div>
            )}
          </>
        )}
        {(upload === "C100" || upload === "Both") && (
          <div>
            <div className="form-control col-flex">
              <h3>
                If the case is not suitable for mediation OR Client is not
                willing to mediate OR the client requires the court form, please
                upload C100<span className="color-red">*</span>
              </h3>
              <label htmlFor="c100" className="file-label">
                Upload File - {state?.c100?.name}
              </label>
              <input
                type="file"
                id="c100"
                onChange={handleFileSelect}
                required={state?.c100?.name === undefined ? true : false}
                style={{
                  display: "none",
                }}
              />
            </div>
          </div>
        )}
        {(upload === "Form A" || upload === "Both") && (
          <div>
            <div className="form-control col-flex">
              <h3>
                If the case is not suitable for mediation OR Client is not
                willing to mediate OR the client requires the court form, please
                upload Form A
              </h3>
              <label htmlFor="formA" className="file-label">
                Upload File - {state?.formA?.name}
              </label>
              <input
                type="file"
                id="formA"
                onChange={handleFormAFileSelect}
                required={state?.formA?.name === undefined ? true : false}
                style={{
                  display: "none",
                }}
              />
            </div>
          </div>
        )}
        {state.mediationClient === "Client 1" && (
          <div className="d-flex">
            <div className="form-control">
              <SelectInput
                label="Do you want to send an invitation to C2?"
                required={true}
                options={clientTwoInvitationOptions}
                optionValue={clientTwoInvitation}
                onOptionChange={onClientTwoInvitation}
              />
            </div>
          </div>
        )}
        <div className="d-flex">
          <div className="form-control">
            <TextArea
              label="Any comments to DMS admin team?"
              id="comment-admin"
              guide="In case there are no comments, please type: None"
              value={DMSAdmin}
              onTextChange={onAdminChange}
              required={true}
              classes="comment height-102"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalComments;
