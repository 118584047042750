const SelectInput = ({
  options,
  label,
  guide,
  optionValue,
  onOptionChange,
  required,
  anotherGuide,
  boldGuide,
}) => {
  const optionsSelected = options.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  return (
    <>
      <label>
        {label}
        {required && label && <span className="color-red">*</span>}
      </label>
      {anotherGuide && (
        <p className="guide-label">
          {boldGuide && <strong>{boldGuide} </strong>}
          {anotherGuide}
        </p>
      )}
      {guide && <p className="guide-label">{guide}</p>}
      <select
        multiple={false}
        value={optionValue}
        onChange={onOptionChange}
        className="width97"
        required={required}
      >
        <option value="select">--- Select An Option ---</option>
        {optionsSelected}
      </select>
    </>
  );
};

export default SelectInput;
