import { client } from "../axios/axios";

export const patchMiamPart2 = (
  id,
  formPartTwoState,
  pathname,
  setIsSubmitted
) => {
  var parts = formPartTwoState.mediationDate.split("-");
  var formattedDate = parts[2] + "/" + parts[1] + "/" + parts[0];
  const patchMiamPart2Object = {
    mediationDetails: {
      MediatorName: formPartTwoState.mediationName,
      MediatorEmail: formPartTwoState.mediationEmail,
      DateOfMIAM: formattedDate,
      Location: formPartTwoState.mediationLocation,
      C1OrC2: formPartTwoState.mediationClient,
      clientFirstName: formPartTwoState.clientFirstName,
      clientSurName: formPartTwoState.clientSurname,
      clientEmail: formPartTwoState.clientEmailConfirm,
      otherPartySurname: formPartTwoState.otherPartySurname,
    },
    caseDetails: {
      privateOrLegalAid: formPartTwoState.aid,
    },
    comments: {
      MediatorComments: formPartTwoState.comment,
      isDomesticAbuseOrViolence: formPartTwoState.violence,
      isPoliceInvolve: formPartTwoState.policeInvolvement,
      isSocialServiceInvolve: formPartTwoState.socialService,
      isSafeguardingIssues: formPartTwoState.alcohol,
      isCourtOrders: formPartTwoState.orders,
      isClientRequireSignposting: formPartTwoState.requireSignPosting,
    },
    MediationTypes: {
      isClientWillingToGoWithMediation: formPartTwoState.client,
      confirmLegalDispute: formPartTwoState.legal,
      informationGivenToClient: formPartTwoState.adr,
      clientMakeApplication: formPartTwoState.clientMakeApplication,
    },
    FinalComments: {
      isSuitable: formPartTwoState.suitableCase,
      uploadCourtForm: formPartTwoState.upload,
      CommentsToDMS: formPartTwoState.DMSAdmin,
      sendCourtAutomatically: formPartTwoState.automatically,
      sendInvitationToClientTwo: formPartTwoState.clientTwoSendInvitation,
    },
  };

  if (patchMiamPart2Object.mediationDetails.Location === "Other") {
    patchMiamPart2Object.mediationDetails.SpecifiedLocation =
      formPartTwoState.mediationSpecifyLocation;
  }
  if (patchMiamPart2Object.caseDetails.privateOrLegalAid === "Private") {
    patchMiamPart2Object.caseDetails.paymentMade = formPartTwoState.appointment;
    patchMiamPart2Object.caseDetails.advancePayment =
      formPartTwoState.advPayment;
    if (
      patchMiamPart2Object.caseDetails.privateOrLegalAid === "Private" &&
      patchMiamPart2Object.caseDetails.paymentMade === "No"
    ) {
      patchMiamPart2Object.caseDetails.whyPaymentNotMade =
        formPartTwoState.payment;
    }
  }
  if (patchMiamPart2Object.comments.isDomesticAbuseOrViolence === "Yes") {
    patchMiamPart2Object.comments.domesticAbuseOrViolenceDetails =
      formPartTwoState.abuse;
  }
  if (patchMiamPart2Object.comments.isPoliceInvolve === "Yes") {
    patchMiamPart2Object.comments.policeInvolveDetails =
      formPartTwoState.policeInvolve;
  }
  if (patchMiamPart2Object.comments.isSocialServiceInvolve === "Yes") {
    patchMiamPart2Object.comments.socialServiceInvolveDetails =
      formPartTwoState.socialDetails;
  }
  if (patchMiamPart2Object.comments.isSafeguardingIssues === "Yes") {
    patchMiamPart2Object.comments.safeguardingIssuesDetails =
      formPartTwoState.safeguard;
  }
  if (patchMiamPart2Object.comments.isCourtOrders === "Yes") {
    patchMiamPart2Object.comments.courtOrdersDetails =
      formPartTwoState.ordersDetails;
  }
  if (patchMiamPart2Object.comments.isClientRequireSignposting === "Yes") {
    patchMiamPart2Object.comments.clientRequireSignpostingDetails =
      formPartTwoState.signPost;
  }
  if (
    patchMiamPart2Object.MediationTypes.isClientWillingToGoWithMediation ===
    "Yes"
  ) {
    patchMiamPart2Object.MediationTypes.mediationFormPreference =
      formPartTwoState.attend;
    patchMiamPart2Object.MediationTypes.clientPreference =
      formPartTwoState.clientPrefer;
    if (patchMiamPart2Object.MediationTypes.clientPreference === "In Person") {
      patchMiamPart2Object.MediationTypes.areSeparateWaitingAreasRequired =
        formPartTwoState.waiting;
      patchMiamPart2Object.MediationTypes.areSeparateArrivalAndDepartureTimesRequired =
        formPartTwoState.arrival;
    }
  } else if (
    patchMiamPart2Object.MediationTypes.isClientWillingToGoWithMediation ===
    "No"
  ) {
    patchMiamPart2Object.MediationTypes.reasonsForRefusal =
      formPartTwoState.refusal;
  }
  if (
    patchMiamPart2Object.MediationTypes.confirmLegalDispute !==
    "Property & Finance"
  ) {
    patchMiamPart2Object.MediationTypes.isChildInclusiveAppropriate =
      formPartTwoState.appropriate;
    if (
      patchMiamPart2Object.MediationTypes.isChildInclusiveAppropriate === "Yes"
    ) {
      patchMiamPart2Object.MediationTypes.doesClientWishForChildInclusive =
        formPartTwoState.childInclusive;
    }
  }
  if (patchMiamPart2Object.MediationTypes.informationGivenToClient === "No") {
    patchMiamPart2Object.MediationTypes.explainWhyADRInfoWasNotProvided =
      formPartTwoState.explainADR;
  }

  if (patchMiamPart2Object.FinalComments.isSuitable === "No") {
    patchMiamPart2Object.FinalComments.whyNotSuitable =
      formPartTwoState.commentCase;
  }

  if (pathname === `/MIAM_PART_2/C1/${id}`) {
    client
      .patch(`addC1MIAM2/${id}`, patchMiamPart2Object, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setIsSubmitted(true);
        localStorage.removeItem(`formPartTwoState-${id}`);
      })
      .catch((err) => console.log({ err: err.message }));
  } else if (pathname === `/MIAM_PART_2/C2/${id}`) {
    client
      .patch(`addC2MIAM2/${id}`, patchMiamPart2Object, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setIsSubmitted(true);
        localStorage.removeItem(`formPartTwoState-${id}`);
      })
      .catch((err) => console.log({ err: err.message }));
  }
};
