import { useState } from "react";

import useScrollTo from "../../hooks/useScrollTo";
import SelectInput from "../FormComponents/SelectInput";
import TextInput from "../FormComponents/TextInput";

const locationOptions = ["Whatsapp Video", "Zoom", "In Person"];
const clientOptions = ["C1", "C2"];

const FirstForm = ({ state, dispatch, records, clientRecord }) => {
  const [mediationName, setMediationName] = useState(state.mediationName);
  const [mediationEmail, setMediationEmail] = useState(
    state.mediationEmail || ""
  );
  const [mediationDate, setMediationDate] = useState(state.mediationDate || "");
  const [mediationLocation, setMediationLocation] = useState(
    state.mediationLocation
  );
  const [mediationSpecifyLocation, setMediationSpecifyLocation] = useState(
    state.mediationSpecifyLocation || ""
  );
  const [mediationClient, setMediationClient] = useState(state.mediationClient);
  const [clientFirstName, setClientFirstName] = useState(
    state.clientFirstName || ""
  );
  const [clientSurname, setClientSurname] = useState(state.clientSurname || "");
  const [clientEmailConfirm, setClientEmailConfirm] = useState(
    state.clientEmailConfirm || ""
  );
  const [otherPartySurname, setOtherPartySurname] = useState(
    state.otherPartySurname || ""
  );

  useScrollTo(0, 0, "smooth");

  const onMediationNameChange = ({ target }) => {
    setMediationName(target.value);
    dispatch({ type: "MEDIATION_NAME", payload: target.value });
  };
  const onMediationEmailChange = ({ target }) => {
    setMediationEmail(target.value);
    dispatch({ type: "MEDIATION_EMAIL", payload: target.value });
  };
  const onMediationDateChange = ({ target }) => {
    setMediationDate(target.value);
    dispatch({ type: "MEDIATION_DATE", payload: target.value });
  };
  const onMediationLocationChange = ({ target }) => {
    setMediationLocation(target.value);
    dispatch({ type: "MEDIATION_LOCATION", payload: target.value });
    dispatch({ type: "REMOVE_MEDIATION_SPECIFY_LOCATION" });
  };
  const onMediationSpecifyLocationChange = ({ target }) => {
    setMediationSpecifyLocation(target.value);
    dispatch({ type: "MEDIATION_SPECIFY_LOCATION", payload: target.value });
  };
  const onMediationClientChange = ({ target }) => {
    setMediationClient(target.value);
    dispatch({ type: "MEDIATION_CLIENT", payload: target.value });
  };
  const onClientFirstNameChange = ({ target }) => {
    setClientFirstName(target.value);
    dispatch({ type: "CLIENT_FIRST_NAME", payload: target.value });
  };
  const onClientSurnameChange = ({ target }) => {
    setClientSurname(target.value);
    dispatch({ type: "CLIENT_SURNAME", payload: target.value });
  };
  const onClientEmailConfirmChange = ({ target }) => {
    setClientEmailConfirm(target.value);
    dispatch({ type: "CLIENT_EMAIL_CONFIRM", payload: target.value });
  };
  const onOtherPartySurnameChange = ({ target }) => {
    setOtherPartySurname(target.value);
    dispatch({ type: "OTHER_PARTY_SURNAME", payload: target.value });
  };

  return (
    <div>
      <div className="first-page-header">
        <h1>DIRECT MEDIATION SERVICES</h1>
        <h1>MIAM - Part 2</h1>
      </div>
      <div className="form-body">
        <div className="d-flex">
          <div className="form-control half-form">
            <TextInput
              value={mediationName}
              label="Mediator's Name"
              required={true}
              onChangeText={onMediationNameChange}
            />
          </div>
          <div className="form-control half-form">
            <TextInput
              value={mediationEmail}
              label="Mediator's email"
              required={true}
              onChangeText={onMediationEmailChange}
            />
          </div>
        </div>
        <div className="d-flex ">
          <div className="form-control half-form">
            <label htmlFor="mediator-email">
              Date of MIAM
              <span className="color-red">*</span>
            </label>
            <input
              type="date"
              required
              id="mediator-date"
              value={mediationDate}
              onChange={onMediationDateChange}
              max={new Date().toLocaleDateString("fr-ca")}
            />
          </div>
        </div>
        <div className="d-flex col-flex">
          <SelectInput
            label="Location"
            options={locationOptions}
            optionValue={mediationLocation}
            onOptionChange={onMediationLocationChange}
            guide="Please choose from the list by clicking on the box below."
            required={true}
          />
        </div>
        <div className="d-flex">
          {mediationLocation === "Other" && (
            <div className="form-control half-form ">
              <TextInput
                label="Please specify location"
                id="mediator-location"
                required={true}
                onChangeText={onMediationSpecifyLocationChange}
                value={mediationSpecifyLocation}
              />
            </div>
          )}
        </div>
        <div className="d-flex col-flex">
          <div className="form-control">
            <SelectInput
              label="MIAM for C1 or C2?"
              options={clientOptions}
              optionValue={mediationClient}
              onOptionChange={onMediationClientChange}
              guide="Please choose from the list by clicking on the box below."
              required={true}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="form-control half-form">
            <TextInput
              label="Provide the first name of the client"
              id="client-first-name"
              required={true}
              value={clientFirstName}
              onChangeText={onClientFirstNameChange}
            />
          </div>
          <div className="form-control half-form">
            <TextInput
              label="Provide the surname of the client"
              id="client-surname-name"
              required={true}
              value={clientSurname}
              onChangeText={onClientSurnameChange}
            />
          </div>
        </div>
        <div className="d-flex ">
          <div className="form-control half-form">
            <label htmlFor="mediator-email">
              Confirm the email of the client
              <span className="color-red">*</span>
            </label>
            <input
              type="email"
              id="mediator-confirm-email"
              required
              value={clientEmailConfirm}
              onChange={onClientEmailConfirmChange}
            />
          </div>
        </div>
        <div className="d-flex col-flex">
          <TextInput
            label="Provide the surname of the other party of the conflict"
            id="otherPartySurname"
            required={true}
            value={otherPartySurname}
            onChangeText={onOtherPartySurnameChange}
          />
        </div>
      </div>
    </div>
  );
};

export default FirstForm;
